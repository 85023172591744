<template>
    <div class="overFlowAuto">
        <table class="hidden-xs hidden-sm transparent table marginTop20">
            <thead>
                <tr>
                    <th class="width15percent"></th>
                    <template v-for="(rating, rating_idx) in question.ratingSettings">
                        <th class="width150px">{{ rating.weight }}</th>
                    </template>
                </tr>
                <tr v-if="hasLabel">
                    <th></th>
                    <template v-for="(rating, rating_idx) in question.ratingSettings">
                        <th class="hidden-xs hidden-sm">{{ rating.label }}</th>
                    </template>
                </tr>
                <tr v-if="hasRubric" class="rubricDisplayDesign">
                    <th></th>
                    <template v-for="(rating, rating_idx) in question.ratingSettings">
                        <th class="hidden-xs hidden-sm">
                            <div>{{ rating.rubric }}</div>
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-if="type == 'peer'">
                    <template v-for="(model, model_idx) in question.peers">
                        <tr tabindex="0">
                            <td class="maxWidth250">
                                <div class="inlineFlex">
                                    <i
                                        class="fas marginTop5"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                    ></i>
                                    &nbsp;
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.fullname) }}
                                        </div>
                                    </div>
                                    <div class="ratingNameColumn" :class="{ warningColor: model.isMe }">
                                        <template v-if="!model.isMe">{{ model.fullname }}</template>
                                        <template v-if="model.isMe">Me</template>
                                    </div>
                                </div>
                            </td>

                            <template v-for="(rating, rating_idx) in question.ratingSettings">
                                <td>
                                    <div class="radioField ratingRadioButton">
                                        <input
                                            :id="'peer_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            :aria-label="
                                                rating.weight +
                                                ((rating.label != null ? rating.label : '') || (rating.rubric != null ? rating.rubric : '')) +
                                                ' for ' +
                                                (!model.isMe ? model.fullname : 'myself')
                                            "
                                            @change="$emit('changed', question.peers)"
                                        />
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
                <template v-if="type == 'team'">
                    <template v-for="(model, model_idx) in question.teams">
                        <tr tabindex="0">
                            <td class="maxWidth250">
                                <div class="inlineFlex">
                                    <i
                                        class="fas marginTop5"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                    ></i>
                                    &nbsp;
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.name) }}
                                        </div>
                                    </div>
                                    <div class="ratingNameColumn" :class="{ warningColor: model.isMe }">
                                        {{ model.name }}
                                        <template v-if="model.isMe">(Me)</template>
                                    </div>
                                </div>
                            </td>
                            <template v-for="(rating, rating_idx) in question.ratingSettings">
                                <td>
                                    <div class="radioField ratingRadioButton">
                                        <input
                                            :id="'team_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            :aria-label="
                                                rating.weight +
                                                ((rating.label != null ? rating.label : '') || (rating.rubric != null ? rating.rubric : '')) +
                                                ' for ' +
                                                model.name +
                                                (model.isMe ? ' my team' : '')
                                            "
                                            @change="$emit('changed', question.teams)"
                                        />
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
                <template v-if="type == 'instructor'">
                    <template v-for="(model, model_idx) in question.instructors">
                        <tr tabindex="0">
                            <td class="maxWidth250">
                                <div class="inlineFlex">
                                    <i
                                        class="fas marginTop5"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                    ></i>
                                    &nbsp;
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.name) }}
                                        </div>
                                    </div>
                                    <div class="ratingNameColumn" :class="{ warningColor: model.isMe }">
                                        {{ model.name }}
                                        <template v-if="model.isMe">(Me)</template>
                                    </div>
                                </div>
                            </td>
                            <template v-for="(rating, rating_idx) in question.ratingSettings">
                                <td>
                                    <div class="radioField ratingRadioButton">
                                        <input
                                            :id="'instructor_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            :aria-label="
                                                rating.weight +
                                                ((rating.label != null ? rating.label : '') || (rating.rubric != null ? rating.rubric : '')) +
                                                ' for ' +
                                                model.name +
                                                (model.isMe ? ' myself' : '')
                                            "
                                            @change="$emit('changed', question.instructors)"
                                        />
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>

        <div class="hidden-md hidden-lg">
            <template v-if="type == 'peer'">
                <template v-for="(model, model_idx) in question.peers">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#peer_' + question_idx + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                        ></i>
                                        &nbsp;
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.fullname) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.fullname }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>
                            </div>
                            <div :id="'peer_' + question_idx + '_' + model_idx" class="panel-body collapse">
                                <template v-for="(rating, rating_idx) in question.ratingSettings">
                                    <div
                                        class="radioField flex"
                                        :class="{
                                            marginTop25: rating_idx != 0,
                                        }"
                                    >
                                        <input
                                            :id="'peer_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            aria-label="Weight"
                                            @change="$emit('changed', question.peers)"
                                        />
                                        <label :for="'peer_' + question_idx + '_' + model_idx + '_' + rating.weight">
                                            <div class="marginLeft5 lineHeight1_2">
                                                <div class="fontBold">
                                                    {{ rating.weight }}
                                                    {{ rating.label }}
                                                </div>
                                                <div>{{ rating.rubric }}</div>
                                            </div>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="type == 'team'">
                <template v-for="(model, model_idx) in question.teams">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#team_' + question_idx + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                        ></i>
                                        &nbsp;
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.name) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.name }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>
                            </div>

                            <div :id="'team_' + question_idx + '_' + model_idx" class="panel-body collapse">
                                <template v-for="(rating, rating_idx) in question.ratingSettings">
                                    <div
                                        class="radioField flex"
                                        :class="{
                                            marginTop25: rating_idx != 0,
                                        }"
                                    >
                                        <input
                                            :id="'team_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            aria-label="Weight"
                                            @change="$emit('changed', question.teams)"
                                        />
                                        <label :for="'team_' + question_idx + '_' + model_idx + '_' + rating.weight">
                                            <div class="marginLeft5 lineHeight1_2">
                                                <div class="fontBold">
                                                    {{ rating.weight }}
                                                    {{ rating.label }}
                                                </div>
                                                <div>{{ rating.rubric }}</div>
                                            </div>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="type == 'instructor'">
                <template v-for="(model, model_idx) in question.instructors">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#instructor_' + question_idx + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                        ></i>
                                        &nbsp;
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.name) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.name }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>
                            </div>
                            <div :id="'instructor_' + question_idx + '_' + model_idx" class="panel-body collapse">
                                <template v-for="(rating, rating_idx) in question.ratingSettings">
                                    <div
                                        class="radioField flex"
                                        :class="{
                                            marginTop25: rating_idx != 0,
                                        }"
                                    >
                                        <input
                                            :id="'instructor_' + question_idx + '_' + model_idx + '_' + rating.weight"
                                            v-model="model.response"
                                            type="radio"
                                            :disabled="disabled ? true : undefined"
                                            :value="rating.weight"
                                            aria-label="Weight"
                                            @change="$emit('changed', question.instructors)"
                                        />
                                        <label :for="'instructor_' + question_idx + '_' + model_idx + '_' + rating.weight">
                                            <div class="marginLeft5 lineHeight1_2">
                                                <div class="fontBold">
                                                    {{ rating.weight }}
                                                    {{ rating.label }}
                                                </div>
                                                <div>{{ rating.rubric }}</div>
                                            </div>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props: ['testObj', 'question', 'question_idx', 'disabled', 'type'],
    computed: {
        hasLabel() {
            //console.log(this.question.ratingSettings)
            if (this.question.type == 'rating' && this.question.ratingSettings != null) {
                for (var i = 0; i < this.question.ratingSettings.length; i++) {
                    let label = this.question.ratingSettings[i].label;
                    if (label) {
                        label = label.trim();
                    }
                    if (label) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasRubric() {
            if (this.question.type == 'rating' && this.question.ratingSettings != null) {
                for (var i = 0; i < this.question.ratingSettings.length; i++) {
                    let rubric = this.question.ratingSettings[i].rubric;
                    if (rubric) {
                        rubric = rubric.trim();
                    }
                    if (rubric) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    watch: {
        question: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    mounted() {
        if (this.question._isCorrect == null) {
            this.question._isCorrect = false;
            //Vue.set(this.question, '_isCorrect', false);
        }
        this.init();
    },
    methods: {
        init() {
            for (var i = 0; i < this.question.ratingSettings.length; i++) {
                this.question.ratingSettings[i].weight = parseFloat(this.question.ratingSettings[i].weight).toFixed(2);
            }
            if (this.type == 'peer') {
                for (var i = 0; i < this.question.peers.length; i++) {
                    if (this.question.peers[i].response) {
                        this.question.peers[i].response = parseFloat(this.question.peers[i].response).toFixed(2);
                    }
                    if (!this.isCorrect(i)) {
                        this.question._isCorrect = false;
                        return;
                    }
                }
                this.question._isCorrect = true;
            } else if (this.type == 'team') {
                for (var i = 0; i < this.question.teams.length; i++) {
                    if (this.question.teams[i].response) {
                        this.question.teams[i].response = parseFloat(this.question.teams[i].response).toFixed(2);
                    }
                    if (!this.isCorrect(i)) {
                        this.question._isCorrect = false;
                        return;
                    }
                }
                this.question._isCorrect = true;
            } else if (this.type == 'instructor') {
                for (var i = 0; i < this.question.instructors.length; i++) {
                    if (this.question.instructors[i].response) {
                        this.question.instructors[i].response = parseFloat(this.question.instructors[i].response).toFixed(2);
                    }
                    if (!this.isCorrect(i)) {
                        this.question._isCorrect = false;
                        return;
                    }
                }
                this.question._isCorrect = true;
            }
        },
        isCorrect(tab = 0) {
            if (this.type == 'peer') {
                if (this.question.peers[tab].response == null) {
                    return false;
                }
            } else if (this.type == 'team') {
                if (this.question.teams[tab].response == null) {
                    return false;
                }
            } else if (this.type == 'instructor') {
                if (this.question.instructors[tab].response == null) {
                    return false;
                }
            }

            return true;
        },
        initials(name) {
            return name.charAt(0);
        },
    },
};
</script>
<style scoped lang="scss">
.mini_badgeProfile {
    height: 24px;
    width: 24px;
    background: #989b9c;
    border-radius: 50%;
}

.profile_image.marginRight5 {
    margin-right: 5px;
}

.gridBase table {
    table-layout: fixed;
    width: 100%;
}
.transparent.table,
table.transparent th,
table.transparent td {
    border: 0;
    box-shadow: none;
    text-align: center;
}

.transparent.table thead tr {
    background-image: none;
    background: none;
    box-shadow: none;
}

table.transparent tbody tr td {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td {
    padding: 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td:first-child {
    padding: 20px 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr td {
    padding: 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

/*table.transparent tbody tr:not(:first-child) td:first-child {
	padding:10px 20px 0 0;
	text-align:left;
	font-weight:bold;
	vertical-align:middle;
}*/

table.transparent tbody tr:last-child td {
    padding-bottom: 0;
}

table.transparent td:last-child div {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table > thead > tr > th {
    vertical-align: middle;
}

.transparent.table > thead > tr:first-child > th {
    padding: 0 0 5px;
}

.transparent.table > thead > tr:nth-child(2) > th {
    padding: 5px 20px 10px;
}

.transparent.table > thead > tr:last-child > th {
    padding: 5px 20px 0;
}

.transparent.table td:nth-child(2) div.gradient.btn-default {
    border-left: 1px solid #d8d8d8;
    border-right: 0;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.transparent.table td:not(:nth-child(2)) div.gradient.btn-default {
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    border-radius: 0;
    box-shadow: none;
}

.transparent.table td:last-child div.gradient.btn-default {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table td:nth-child(2):last-child div.gradient.btn-default {
    border: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) th:not(:first-child) {
    border: 1px solid #d8d8d8;
    border-right: 0;
    padding: 20px;
    text-transform: none;
}

.transparent.table thead tr:nth-child(3) th:last-child {
    border-right: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) {
    margin-bottom: 10px;
}

.transparent {
    td {
        .ratingRadioButton {
            border: solid 1px #d8d8d8;
            box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
            padding: 6px 12px !important;
            height: 36px;
            border-radius: 0;
        }

        &:nth-child(2) {
            .ratingRadioButton {
                border-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-left: solid 1px #d8d8d8;
            }
        }

        &:last-child {
            .ratingRadioButton {
                border-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-right: solid 1px #d8d8d8;
            }
        }
    }
}

.radioField {
    input {
        display: block;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #222;
        background: #fff;
        margin-right: 5px;

        &:hover {
            border: 2px solid #0071be;
        }

        &:checked {
            border-color: #0071be;
            box-shadow: inset 0 0 0 3px #fff;
            background: #0071be;

            &:before {
                content: inherit;
            }

            &:disabled {
                box-shadow: inset 0 0 0 3px #fff;
                border-color: #d8d8d8;
                background: #d8d8d8;
            }
        }

        &:disabled {
            border-color: #d8d8d8;
            background: #d8d8d8;
        }
    }
}

@media (max-width: 991px) {
    .evaluation.radioField.ratingRadioButton:not(:last-child) {
        margin-bottom: 20px;
    }
}

.width15percent {
    width: 15%;
}

.width150px {
    min-width: 60px;
}

.badge_profile {
    font-size: 12px;
}
</style>

<style>
.ratingNameColumn {
    word-wrap: break-word;
    width: 56%;
}

.maxWidth250 {
    max-width: 200px;
}
</style>
