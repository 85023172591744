<template>
    <div>
        <kr-panel v-if="component_done_loading" :with-footer="false">
            <template #title>
                <div class="flexSpaceBetween align-items width100">
                    <label class="autoFlex" :class="{ width110px: !manage }">
                        <template v-if="!manage"> All Students ({{ course.students.length }}) </template>
                        <template v-else> Manage Students ({{ course.students.length }}) </template>
                    </label>

                    <div v-if="hasWritePrivilege(course)" class="autoFlex justify-content-right">
                        <template v-if="!manageAccommodations">
                            <div v-if="!manage" class="col-xs-12 flexOnly">
                                <div class="hidden-xs hidden-sm">
                                    <button
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        tag="button"
                                        class="btn btn-primary"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                        @click="toggleManageAccommodations"
                                    >
                                        <i class="fas fa-universal-access marginRight8" aria-hidden="true" />Accommodations
                                    </button>
                                    <button
                                        v-if="canClickRosterSync"
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn btn-primary mainBtn"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                        aria-label="Roster Sync"
                                        @click.prevent="confirmSyncRoster"
                                    >
                                        <i class="fas fa-flask marginRight8" aria-hidden="true" />Roster Sync
                                    </button>
                                    <button
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn btn-primary mainBtn"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                        aria-label="Organise Students"
                                        @click.prevent="manage = true"
                                    >
                                        <i class="fas fa-user-cog marginRight8" aria-hidden="true" />Organise Students
                                    </button>

                                    <router-link
                                        v-if="canAddStudents"
                                        v-slot="{ navigate }"
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        :to="{
                                            name: 'courses.manage.students.create',
                                            params: { course_id: course.uuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-success" :disabled="hasWritePrivilege(course) == -1 ? true : undefined" @click="navigate">
                                            <i class="fa fa-plus marginRight8" aria-hidden="true" />New Student(s)
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-if="canInviteStudents"
                                        v-slot="{ navigate }"
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        :to="{
                                            name: 'courses.manage.students.invite',
                                            params: { course_id: course.uuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-primary" :disabled="hasWritePrivilege(course) == -1 ? true : undefined" @click="navigate">
                                            <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />Send Invites
                                        </button>
                                    </router-link>

                                    <div
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="dropdown"
                                    >
                                        <button
                                            class="btn btn-primary margin0"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                            aria-label="Export"
                                        >
                                            <i class="fa fa-download marginRight8" aria-hidden="true" />Export
                                            <i class="fa fa-caret-down marginLeft8" style="color: #fff" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <a class="primary-state" href="#" aria-label="Full Format" @click.prevent="downloadStudents('full')">
                                                        <span> <i class="fa fa-table marginRight8" aria-hidden="true" />Full Format </span>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="primary-state" href="#" aria-label="Zoom Import" @click.prevent="downloadStudents('zoom')">
                                                        <span> <i class="fa fa-list-alt marginRight8" aria-hidden="true" />Zoom Import </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <button
                                        v-if="canClickRosterSync"
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn btn-primary mainBtn"
                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                        aria-label="Roster Sync"
                                        @click.prevent="confirmSyncRoster"
                                    >
                                        <i class="fas fa-flask marginRight8" aria-hidden="true" />Roster Sync
                                    </button>
                                    <div
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn"
                                        :class="{
                                            'p-0-i': hasWritePrivilege(course),
                                        }"
                                    >
                                        <button
                                            class="btn btn-primary mainBtn"
                                            :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                            aria-label="Organise Students"
                                            @click.prevent="manage = true"
                                        >
                                            <i class="fas fa-user-cog" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn"
                                        :class="{
                                            'p-0-i': hasWritePrivilege(course),
                                        }"
                                    >
                                        <router-link
                                            v-if="canAddStudents"
                                            v-slot="{ navigate }"
                                            v-tooltip="'Add New Students'"
                                            :to="{
                                                name: 'courses.manage.students.create',
                                                params: {
                                                    course_id: course.uuid,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-success" :disabled="hasWritePrivilege(course) == -1 ? true : undefined" @click="navigate">
                                                <i class="fa fa-plus" aria-hidden="true" />
                                            </button>
                                        </router-link>
                                    </div>

                                    <div
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn"
                                        :class="{
                                            'p-0-i': hasWritePrivilege(course),
                                        }"
                                    >
                                        <router-link
                                            v-if="canInviteStudents"
                                            v-slot="{ navigate }"
                                            v-tooltip="'Send Invites'"
                                            :to="{
                                                name: 'courses.manage.students.invite',
                                                params: {
                                                    course_id: course.uuid,
                                                },
                                            }"
                                            custom
                                        >
                                            <button
                                                class="btn btn-primary"
                                                :disabled="course.students.length == 0 || hasWritePrivilege(course) == -1 ? true : undefined"
                                                @click="navigate"
                                            >
                                                <i class="fa fa-paper-plane" aria-hidden="true" />
                                            </button>
                                        </router-link>
                                    </div>

                                    <div
                                        v-tooltip="{
                                            content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn-group"
                                        style="margin-left: 20px"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary mainBtn dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                            aria-label="Export"
                                        >
                                            <i class="fa fa-download" aria-hidden="true" />
                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <a class="primary-state" href="#" @click.prevent="downloadStudents('full')">
                                                        <span> <i class="fa fa-table marginRight8" aria-hidden="true" />Full Format </span>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="primary-state" href="#" @click.prevent="downloadStudents('zoom')">
                                                        <span> <i class="fa fa-list-alt marginRight8" aria-hidden="true" />Zoom Import </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="buttonGroup">
                                <div class="hidden-xs hidden-sm">
                                    <button
                                        v-if="studentsDetailedView"
                                        :disabled="disenrolled_students_length == 0 ? true : undefined"
                                        class="btn btn-danger mainBtn"
                                        aria-label="Disenroll"
                                        @click="massDisenrollStudents"
                                    >
                                        Disenroll
                                    </button>
                                    <button class="btn btn-success mainBtn" @click="saveManageStudents">
                                        <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                                    </button>
                                    <button
                                        class="btn btn-default mainBtn"
                                        aria-label="Cancel"
                                        @click="
                                            manage = false;
                                            reset();
                                        "
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <button
                                        v-if="studentsDetailedView"
                                        :disabled="disenrolled_students_length == 0 ? true : undefined"
                                        class="btn btn-danger mainBtn"
                                        aria-label="Disenroll"
                                        @click="massDisenrollStudents"
                                    >
                                        Disenroll
                                    </button>
                                    <button class="btn btn-success mainBtn" aria-label="Update" @click="saveManageStudents">
                                        <i class="fa fa-check" aria-hidden="true" />
                                    </button>
                                    <button
                                        class="btn btn-default mainBtn"
                                        aria-label="Cancel"
                                        @click="
                                            manage = false;
                                            reset();
                                        "
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <button class="btn btn-default" @click="toggleManageAccommodations">Back to Students</button>
                        </template>
                    </div>
                </div>
            </template>
            <template #content>
                <teams-students-form
                    v-if="!manageAccommodations"
                    :course-obj="course"
                    :manage="manage"
                    @changed="manage = false"
                    @students-detailed-view="processStudentsDetailedView"
                    @unassigned_students_length="processUnassignedStudentsLength"
                    @disenrolled_students_length="processDisenrolledStudentsLength"
                    @update-manage-accommodations="toggleManageAccommodations"
                    @get-back-to-module-tab="getBackToModule"
                />
                <accommodations-edit v-if="manageAccommodations" :manage-accommodations="manageAccommodations" />
                <div v-if="manage" class="col-xs-12 col-md-4 marginTop30 buttonGroup">
                    <button class="btn btn-success mainBtn" @click="saveManageStudents"><i class="fa fa-check marginRight8" aria-hidden="true" />Update</button>
                    <button
                        class="btn btn-default mainBtn"
                        aria-label="Cancel"
                        @click="
                            manage = false;
                            reset();
                        "
                    >
                        Cancel
                    </button>
                </div>
            </template>
        </kr-panel>
        <div id="studentInviteConfirmation" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="studentInviteConfirmation-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 id="studentInviteConfirmation-title" class="modal-title">Archive This Module</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <div>Are you sure you want to archive this module?</div>
                        <div>All associated activities will also be hidden until you reinstate this module.</div>
                        <div class="flex marginTop30">
                            <button class="btn btn-danger mainBtn" aria-label="Archive" @click.prevent="archiveModule()">
                                <i class="fa fa-trash marginRight8" aria-hidden="true" />Archive
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="rosterSyncConfirmationModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="rosterSyncConfirmationModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="rosterSyncConfirmationModal-title" class="modal-title">Important Notice: Syncing Roster from LMS to this course</h2>
                    </div>

                    <div class="modal-body">
                        <div class="col-xs-12">
                            <h3>
                                You are about to sync the roster from your Learning Management System to this course. Students who don’t have a team yet will be
                                added as UNASSIGNED.
                            </h3>
                            <br />
                            <p>
                                Please be aware that this functionality is currently in beta phase and is part of LTI 1.3 Names and Roles Services (Roster
                                Sync).
                            </p>
                            <br />
                            <p>To proceed, ensure that you are connected as teacher using LTI 1.3.</p>
                        </div>

                        <div class="modal-footer bg-white padding20 paddingTop10">
                            <div class="col-md-12 hidden-xs hidden-sm buttonGroup">
                                <button class="btn btn-success" data-dismiss="modal" aria-label="Roster Sync" @click.prevent="syncRoster()">
                                    Yes, Proceed
                                </button>
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <button class="col-xs-12 btn btn-success" data-dismiss="modal" aria-label="Roster Sync" @click.prevent="syncRoster()">
                                    Yes, Proceed
                                </button>

                                <button class="col-xs-12 btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {
        'teams-students-form': require(`./form-partials/partial.teams-students.vue`).default,
        'accommodations-edit': require(`./accommodation.vue`).default,
    },
    props: ['courseObj', 'courseUuid', 'options'],
    data() {
        var auth = new KrAuth();
        return {
            manage: false,
            course: null,
            component_done_loading: false,
            auth: auth,
            canAddStudents: true,
            canInviteStudents: true,
            unassigned_students_length: 0,
            disenrolled_students_length: 0,
            readOnlyLabel: 'You have read-only access',
            studentsDetailedView: false,
            manageAccommodations: false,
        };
    },
    computed: {
        canClickRosterSync() {
            if (
                ([
                    '08299f88-6223-49aa-84e7-1958d703de6e',
                    'd1505db9-f490-4d30-81c8-0501410aecee',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                    '937caf1e-aa76-4f46-a391-86aef3941638',
                    '58be2094-6f74-4087-bba5-ace0b7e3b651',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                    'd89d09f5-ec57-47af-8c4b-dd4291e3100d',
                    '04dee48c-19f0-43b2-87ed-5efb86eed881',
                    '8730eab5-a205-4cb3-85d9-9c10dcf7a1f5',
                    '8726d872-408c-4932-8e78-809ccd1a9a1a',
                    'd6d49ef0-5a7c-4ca9-b2c5-5f9888ffd007',
                    '6d4a2300-db56-41eb-9eaa-1bcab3afdf4b',
                    '58be2094-6f74-4087-bba5-ace0b7e3b651',
                ].includes(this.auth.user().account.uuid) ||
                    process.env.VUE_APP_ENV == 'staging') &&
                this.course.ltiCourseContext
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        //options
        this.canAddStudents = _.get(this, 'options.canAddStudents', true);
        this.canInviteStudents = _.get(this, 'options.canInviteStudents', true);
        if (!this.courseObj) {
            let uuid = this.courseUuid ? this.courseUuid : this.$route.params.id;
            axios.get('/courses/' + uuid).then(function (response) {
                that.course = response.data.data;
                that.component_done_loading = true;
            });
        } else {
            this.course = this.courseObj;
            this.component_done_loading = true;
        }
    },
    methods: {
        downloadStudents(type = 'full') {
            let d = moment().format('YYYY[_]MM[_]DD');
            let suffix = '';
            let filetype = 'xlsx';
            let url = '/students/course/' + this.course.uuid + '/export';
            if (type == 'zoom') {
                url = '/students/course/' + this.course.uuid + '/zoom-room-export';
                suffix = '_zoom';
                filetype = 'csv';
            }
            var that = this;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', that.course.name + (that.course.code ? ' (' + that.course.code + ')' : '') + suffix + '_' + d + '.' + filetype);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        processStudentsDetailedView(bool) {
            this.studentsDetailedView = bool;
        },
        processDisenrolledStudentsLength(length) {
            this.disenrolled_students_length = length;
        },
        processUnassignedStudentsLength(length) {
            this.unassigned_students_length = length;
            this.$emit('unassigned_students_length', length);
        },
        massDisenrollStudents() {
            Events.fire('disenroll_manage_students');
        },
        reset() {
            Events.fire('reset_manage_students_form');
        },
        saveManageStudents() {
            Events.fire('save_manage_students');
        },
        sendInvites() {
            axios.get('/students/course/' + this.course.uuid + '/send-invites').then(function (response) {
                //swal('Success','Invites have been sent','success');
            });
        },
        confirmSyncRoster() {
            $('#rosterSyncConfirmationModal').modal('show');
        },
        syncRoster() {
            var that = this;
            axios
                .post(`/lti/1p3/courses/${this.course.uuid}/roster`)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully synced roster',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong please try again later',
                    });
                });
        },
        toggleManageAccommodations() {
            this.manageAccommodations = !this.manageAccommodations;
            this.$emit('updateManageAccommodations', this.manageAccommodations);
        },
        getBackToModule() {
            this.$emit('getBackToModuleTab');
        },
    },
};
</script>

<style lang="scss" scoped>
#rosterSyncConfirmationModal .modal-body .row:last-child {
    margin-top: 20px;
}

#rosterSyncConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#rosterSyncConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
